import translations from '../services/translations'
import { getGlobalDesignPresets } from '../preset/preset-service'
import { COMPONENT_TYPES } from '../preset/fields/component-types'
import { FIELDS, ROLE_FORM } from '../../../constants/roles'

export enum DESIGN_GROUPS {
  FORM_BACKGROUND = 'form_background',
  INPUT_BACKGROUND = 'input_background',
  INPUT_BACKGROUND_ERROR = 'input_background_error',
  INPUT_BACKGROUND_FOCUS = 'input_background_focus',
  INPUT_BACKGROUND_HOVER = 'input_background_hover',
  INPUT_BORDER_COLOR = 'input_border_color',
  INPUT_BORDER_COLOR_ERROR = 'input_border_color_error',
  INPUT_BORDER_COLOR_FOCUS = 'input_border_color_focus',
  INPUT_BORDER_COLOR_HOVER = 'input_border_color_hover',
  INPUT_BORDER_SIZES = 'input_border_sizes',
  INPUT_BORDER_SIZES_ERROR = 'input_border_sizes_error',
  INPUT_BORDER_SIZES_FOCUS = 'input_border_sizes_focus',
  INPUT_BORDER_SIZES_HOVER = 'input_border_sizes_hover',
  INPUT_CORNERS = 'input_corners',
  LABEL_TEXT_COLOR = 'label_text_color',
  LABEL_TEXT_FONT = 'label_text_font',
  MAIN_TEXT_COLOR = 'main_text_color',
  MAIN_TEXT_COLOR_ERROR = 'main_text_color_error',
  MAIN_TEXT_FONT = 'main_text_font',
  PLACEHOLDER_TEXT_COLOR = 'placeholder_text_color',
  LABEL_REQUIRED_TEXT_COLOR = 'label_required_text_color',
}

const enum CONTROLS_TYPES {
  BG_COLOR_ALPHA = 'BG_COLOR_ALPHA',
  BORDER_COLOR_ALPHA = 'BORDER_COLOR_ALPHA',
  BORDER_SIZES = 'BORDER_SIZES',
  BORDER_RADIUS = 'BORDER_RADIUS',
  TEXT_COLOR = 'TEXT_COLOR',
  FONT = 'FONT',
}

const enum CONTROLS_STATES {
  REGULAR = 'regular',
  HOVER = 'hover',
  FOCUS = 'focus',
  ERROR = 'error',
}

const enum SECTIONS {
  LABEL = 'label',
  INPUT = 'input',
}

const enum COMP_PARTS {
  INPUTS = 'inputs',
  CONTAINER = 'container',
}

const baseValues = {
  [DESIGN_GROUPS.INPUT_BACKGROUND]: ['bg'],
  [DESIGN_GROUPS.INPUT_BORDER_COLOR]: ['brd'],
  [DESIGN_GROUPS.INPUT_BORDER_SIZES]: ['brw'],
  [DESIGN_GROUPS.INPUT_BORDER_SIZES_ERROR]: ['brwe'],
  [DESIGN_GROUPS.INPUT_BORDER_SIZES_HOVER]: ['brwh'],
  [DESIGN_GROUPS.INPUT_BORDER_SIZES_FOCUS]: ['brwf'],
  [DESIGN_GROUPS.INPUT_CORNERS]: ['rd'],
  [DESIGN_GROUPS.MAIN_TEXT_COLOR]: ['txt'],
  [DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: ['txt2'],
  [DESIGN_GROUPS.MAIN_TEXT_FONT]: ['fnt'],
  [DESIGN_GROUPS.LABEL_TEXT_COLOR]: ['txtlbl'],
  [DESIGN_GROUPS.LABEL_TEXT_FONT]: ['fntlbl'],
  [DESIGN_GROUPS.INPUT_BACKGROUND_HOVER]: ['bgh'],
  [DESIGN_GROUPS.INPUT_BORDER_COLOR_HOVER]: ['brdh'],
  [DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS]: ['bgf'],
  [DESIGN_GROUPS.INPUT_BORDER_COLOR_FOCUS]: ['brdf'],
  [DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: ['bge'],
  [DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: ['brde'],
  [DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR]: ['txtlblrq'],
}

const checkboxValues = {
  [DESIGN_GROUPS.INPUT_BACKGROUND]: ['bg'],
  [DESIGN_GROUPS.INPUT_BORDER_COLOR]: ['brd'],
  [DESIGN_GROUPS.INPUT_CORNERS]: ['rd'],
  [DESIGN_GROUPS.LABEL_TEXT_COLOR]: ['txt'],
  [DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: ['bgc', 'bgch', 'bgcd'],
  [DESIGN_GROUPS.MAIN_TEXT_FONT]: ['fnt'],
  [DESIGN_GROUPS.INPUT_BACKGROUND_HOVER]: ['bgh'],
  [DESIGN_GROUPS.INPUT_BORDER_COLOR_HOVER]: ['brdh'],
  [DESIGN_GROUPS.INPUT_BORDER_COLOR_FOCUS]: ['brdf'],
  [DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: ['bge'],
  [DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: ['brde'],
  [DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS]: ['bgf']
}

export const getGroupType = (group: DESIGN_GROUPS) => {
  const groupsManifest = getGroupsManifest()
  return groupsManifest[group].type
}
const getGroupsManifest = () => ({
  [DESIGN_GROUPS.INPUT_BACKGROUND]: {
    type: CONTROLS_TYPES.BG_COLOR_ALPHA,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.INPUT_BORDER_COLOR]: {
    type: CONTROLS_TYPES.BORDER_COLOR_ALPHA,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.INPUT_BORDER_SIZES]: {
    type: CONTROLS_TYPES.BORDER_SIZES,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.INPUT_BORDER_SIZES_ERROR]: {
    type: CONTROLS_TYPES.BORDER_SIZES,
    state: CONTROLS_STATES.ERROR,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.INPUT_BORDER_SIZES_HOVER]: {
    type: CONTROLS_TYPES.BORDER_SIZES,
    state: CONTROLS_STATES.HOVER,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.INPUT_BORDER_SIZES_FOCUS]: {
    type: CONTROLS_TYPES.BORDER_SIZES,
    state: CONTROLS_STATES.FOCUS,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.INPUT_CORNERS]: {
    type: CONTROLS_TYPES.BORDER_RADIUS,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: {
    type: CONTROLS_TYPES.TEXT_COLOR,
    state: CONTROLS_STATES.REGULAR,
    label: translations.t('manifest.globalDesign.placeholderTextColor'),
    section: SECTIONS.INPUT,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.MAIN_TEXT_COLOR]: {
    type: CONTROLS_TYPES.TEXT_COLOR,
    state: CONTROLS_STATES.REGULAR,
    label: translations.t('manifest.globalDesign.InputTextColor'),
    section: SECTIONS.INPUT,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.MAIN_TEXT_FONT]: {
    type: CONTROLS_TYPES.FONT,
    state: CONTROLS_STATES.REGULAR,
    section: SECTIONS.INPUT,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.LABEL_TEXT_COLOR]: {
    type: CONTROLS_TYPES.TEXT_COLOR,
    state: CONTROLS_STATES.REGULAR,
    label: translations.t('manifest.globalDesign.labelTextColor'),
    section: SECTIONS.LABEL,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.LABEL_TEXT_FONT]: {
    type: CONTROLS_TYPES.FONT,
    state: CONTROLS_STATES.REGULAR,
    section: SECTIONS.LABEL,
    category: 'text',
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.INPUT_BACKGROUND_HOVER]: {
    type: CONTROLS_TYPES.BG_COLOR_ALPHA,
    state: CONTROLS_STATES.HOVER,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.INPUT_BORDER_COLOR_HOVER]: {
    type: CONTROLS_TYPES.BORDER_COLOR_ALPHA,
    state: CONTROLS_STATES.HOVER,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS]: {
    type: CONTROLS_TYPES.BG_COLOR_ALPHA,
    state: CONTROLS_STATES.FOCUS,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.INPUT_BORDER_COLOR_FOCUS]: {
    type: CONTROLS_TYPES.BORDER_COLOR_ALPHA,
    state: CONTROLS_STATES.FOCUS,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: {
    type: CONTROLS_TYPES.BG_COLOR_ALPHA,
    state: CONTROLS_STATES.ERROR,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: {
    type: CONTROLS_TYPES.BORDER_COLOR_ALPHA,
    state: CONTROLS_STATES.ERROR,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.MAIN_TEXT_COLOR_ERROR]: {
    type: CONTROLS_TYPES.TEXT_COLOR,
    state: CONTROLS_STATES.ERROR,
    section: SECTIONS.LABEL,
    compPart: COMP_PARTS.INPUTS,
  },
  [DESIGN_GROUPS.FORM_BACKGROUND]: {
    type: CONTROLS_TYPES.BG_COLOR_ALPHA,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.CONTAINER,
  },
  [DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR]: {
    type: CONTROLS_TYPES.TEXT_COLOR,
    state: CONTROLS_STATES.REGULAR,
    label: translations.t('manifest.globalDesign.labelRequiredTextColor'),
    section: SECTIONS.LABEL,
    compPart: COMP_PARTS.INPUTS,
  },
})

export const createGlobalDesignManifest = () => ({
  groupMapping: {
    groups: getGroupsManifest(),
    compParts: [
      {
        label: translations.t('manifest.globalDesign.inputsCompPart'),
        value: COMP_PARTS.INPUTS,
        defaultSelected: true,
      },
      {
        label: translations.t('manifest.globalDesign.containerCompPart'),
        value: COMP_PARTS.CONTAINER,
      },
    ],
    sections: {
      input: {
        state: 'active',
        category: 'text',
        label: translations.t('manifest.globalDesign.inputSection'),
        priority: 2,
      },
      label: {
        state: 'active',
        category: 'text',
        label: translations.t('manifest.globalDesign.labelSection'),
        priority: 1,
      },
    },
  },
  presets: getGlobalDesignPresets(),
  title: translations.t('manifest.globalDesign.title'),
  presetsTitle: translations.t('manifest.globalDesign.presetsTitle'),
  customHelpId: '72d4c414-370d-4bf7-b70c-7a29795bdbda',
  presetHelpId: '56086989-6a7f-400e-985c-d9a42c81c697',
})

const componentTypesDesignMapping: {
  [key in COMPONENT_TYPES]?: { [key in DESIGN_GROUPS]?: string[] }
} = {
  [COMPONENT_TYPES.TEXT_INPUT]: baseValues,
  [COMPONENT_TYPES.TEXT_AREA_INPUT]: baseValues,
  [COMPONENT_TYPES.COMBOBOX]: {
    ...baseValues,
    [DESIGN_GROUPS.MAIN_TEXT_COLOR]: ['txt2'],
    [DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: ['txt', 'arrowColor'],
  },
  [COMPONENT_TYPES.RADIO_GROUP]: {
    [DESIGN_GROUPS.INPUT_BACKGROUND]: ['bg'],
    [DESIGN_GROUPS.INPUT_BORDER_COLOR]: ['bg3'],
    [DESIGN_GROUPS.MAIN_TEXT_FONT]: ['fnt'],
    [DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: ['bg2'],
    [DESIGN_GROUPS.LABEL_TEXT_COLOR]: ['txt2', 'txt'],
    [DESIGN_GROUPS.LABEL_TEXT_FONT]: ['fnt2'],
    [DESIGN_GROUPS.INPUT_BACKGROUND_HOVER]: ['bgh'],
    [DESIGN_GROUPS.INPUT_BORDER_COLOR_HOVER]: ['bg3h'],
    [DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS]: ['bgf'],
    [DESIGN_GROUPS.INPUT_BORDER_COLOR_FOCUS]: ['border_color_focus'],
    [DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: ['bge'],
    [DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: ['bg3e'],
    [DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR]: ['txtlblrq'],
  },
  [COMPONENT_TYPES.CHECKBOX_GROUP]: {
    [DESIGN_GROUPS.INPUT_BACKGROUND]: ['bg'],
    [DESIGN_GROUPS.INPUT_BORDER_COLOR]: ['brd'],
    [DESIGN_GROUPS.INPUT_CORNERS]: ['rd'],
    [DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: ['bgc', 'bgch', 'bgcd'],
    [DESIGN_GROUPS.MAIN_TEXT_FONT]: ['fnt'],
    [DESIGN_GROUPS.LABEL_TEXT_COLOR]: ['txt2', 'txt'],
    [DESIGN_GROUPS.LABEL_TEXT_FONT]: ['fnt2'],
    [DESIGN_GROUPS.INPUT_BACKGROUND_HOVER]: ['bgh'],
    [DESIGN_GROUPS.INPUT_BORDER_COLOR_HOVER]: ['brdh'],
    [DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS]: ['bgf'],
    [DESIGN_GROUPS.INPUT_BORDER_COLOR_FOCUS]: ['brdf'],
    [DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: ['bge'],
    [DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: ['brde'],
    [DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR]: ['txtlblrq'],
  },
  [COMPONENT_TYPES.SINGLE_CHECKBOX]: checkboxValues,
  [COMPONENT_TYPES.DATE_PICKER]: {
    ...baseValues,
    [DESIGN_GROUPS.INPUT_BACKGROUND]: ['bg', 'bg-calendar', 'bg-header'],
    [DESIGN_GROUPS.INPUT_BORDER_COLOR]: ['brd', 'brd-calendar', 'bg-day-selected'],
    [DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: ['txt-placeholder', 'icn-color', 'txt-day', 'txt-header'],
    [DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR]: ['txtlblrq'],
  },
  [COMPONENT_TYPES.FILE_UPLOADER]: {
    [DESIGN_GROUPS.INPUT_BACKGROUND]: ['btn_bg'],
    [DESIGN_GROUPS.INPUT_BORDER_COLOR]: ['btn_brd'],
    [DESIGN_GROUPS.INPUT_CORNERS]: ['btn_rd'],
    [DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: [
      'btn_txt',
      'btn_txt_h',
      'txt_f',
      'txt_e',
      'txt_d',
      'x_file_txt_e',
      'x_file_txt_f',
      'x_file_txt_h',
      'x_file_txt',
    ],
    [DESIGN_GROUPS.MAIN_TEXT_FONT]: ['btn_fnt'],
    [DESIGN_GROUPS.LABEL_TEXT_COLOR]: ['txtlbl'],
    [DESIGN_GROUPS.LABEL_TEXT_FONT]: ['fntlbl'],
    [DESIGN_GROUPS.INPUT_BACKGROUND_HOVER]: ['btn_bg_h'],
    [DESIGN_GROUPS.INPUT_BORDER_COLOR_HOVER]: ['btn_brd_h'],
    [DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS]: ['bg_f'],
    [DESIGN_GROUPS.INPUT_BORDER_COLOR_FOCUS]: ['brd_f'],
    [DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: ['bg_e'],
    [DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: ['brd_e'],
    [DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR]: ['txtlblrq'],
  },
  [COMPONENT_TYPES.RATING]: {
    [DESIGN_GROUPS.INPUT_BACKGROUND]: ['emptyShapeColor'],
    [DESIGN_GROUPS.LABEL_TEXT_COLOR]: ['labelFontColor', 'filledShapeColor', 'shapeBorderColor'],
    [DESIGN_GROUPS.LABEL_TEXT_FONT]: ['labelFont'],
    [DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: ['errorFilledShapeColor'],
    [DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: ['errorShapeBorderColor'],
    [DESIGN_GROUPS.MAIN_TEXT_COLOR_ERROR]: ['errorLabelFontColor'],
  },
  [COMPONENT_TYPES.FORM_CONTAINER]: { [DESIGN_GROUPS.FORM_BACKGROUND]: ['bg'] },
}

export const roleDesignMapping: {
  [role: string]: { [key in COMPONENT_TYPES]?: { [key in DESIGN_GROUPS]?: string[] } }
} = {
  [FIELDS.ROLE_FIELD_TEXT]: {
    [COMPONENT_TYPES.TEXT_INPUT]: componentTypesDesignMapping[COMPONENT_TYPES.TEXT_INPUT],
  },
  [FIELDS.ROLE_FIELD_TEXT_AREA]: {
    [COMPONENT_TYPES.TEXT_AREA_INPUT]: componentTypesDesignMapping[COMPONENT_TYPES.TEXT_AREA_INPUT],
  },
  [FIELDS.ROLE_FIELD_SELECT]: {
    [COMPONENT_TYPES.COMBOBOX]: componentTypesDesignMapping[COMPONENT_TYPES.COMBOBOX],
  },
  [FIELDS.ROLE_FIELD_RADIO_LIST]: {
    [COMPONENT_TYPES.RADIO_GROUP]: componentTypesDesignMapping[COMPONENT_TYPES.RADIO_GROUP],
  },
  [FIELDS.ROLE_FIELD_CHECKBOX]: {
    [COMPONENT_TYPES.SINGLE_CHECKBOX]: componentTypesDesignMapping[COMPONENT_TYPES.SINGLE_CHECKBOX],
  },
  [FIELDS.ROLE_FIELD_CHECKBOX_GROUP]: {
    [COMPONENT_TYPES.CHECKBOX_GROUP]: componentTypesDesignMapping[COMPONENT_TYPES.CHECKBOX_GROUP],
  },
  [FIELDS.ROLE_FIELD_SUBSCRIBE]: {
    [COMPONENT_TYPES.SINGLE_CHECKBOX]: componentTypesDesignMapping[COMPONENT_TYPES.SINGLE_CHECKBOX],
  },
  [FIELDS.ROLE_FIELD_DATE]: {
    [COMPONENT_TYPES.DATE_PICKER]: componentTypesDesignMapping[COMPONENT_TYPES.DATE_PICKER],
  },
  [FIELDS.ROLE_FIELD_FILE_UPLOAD]: {
    [COMPONENT_TYPES.FILE_UPLOADER]: componentTypesDesignMapping[COMPONENT_TYPES.FILE_UPLOADER],
  },
  [FIELDS.ROLE_FIELD_RATING]: {
    [COMPONENT_TYPES.RATING]: componentTypesDesignMapping[COMPONENT_TYPES.RATING],
  },
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL]: {
    [COMPONENT_TYPES.TEXT_INPUT]: componentTypesDesignMapping[COMPONENT_TYPES.TEXT_INPUT],
  },
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD]: {
    [COMPONENT_TYPES.TEXT_INPUT]: componentTypesDesignMapping[COMPONENT_TYPES.TEXT_INPUT],
  },
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_AGREE_TERMS]: {
    [COMPONENT_TYPES.SINGLE_CHECKBOX]: componentTypesDesignMapping[COMPONENT_TYPES.SINGLE_CHECKBOX],
  },
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY]: {
    [COMPONENT_TYPES.SINGLE_CHECKBOX]: componentTypesDesignMapping[COMPONENT_TYPES.SINGLE_CHECKBOX],
  },
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_PRIVACY_POLICY]: {
    [COMPONENT_TYPES.SINGLE_CHECKBOX]: componentTypesDesignMapping[COMPONENT_TYPES.SINGLE_CHECKBOX],
  },
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_CODE_OF_CONDUCT]: {
    [COMPONENT_TYPES.SINGLE_CHECKBOX]: componentTypesDesignMapping[COMPONENT_TYPES.SINGLE_CHECKBOX],
  },
  [ROLE_FORM]: {
    [COMPONENT_TYPES.FORM_CONTAINER]: componentTypesDesignMapping[COMPONENT_TYPES.FORM_CONTAINER],
  },
}
